import {Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AnalogReading, AnalogScratchPad, buildPayloadResponse, DigitalReading} from '../readings/readings.model';
import {UnitDataPoint} from '../../../../core/models/unit-data-point.model';
import {Unit} from '../../../../core/models/unit.model';
import {ReadingsService} from '../readings/readings.service';
import {ChannelSetting, ChannelDisabledResponse} from './channel-settings.models';
import * as _ from 'lodash';
import {ReportingService} from '../../reporting/reporting.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { RM5AnalogChannels } from '../readings/components/channel/channel.models';
import { UnitStyleEnum } from 'app/core/enums/unit-style.enum';
import { BrandType } from 'app/core/enums/brand-type.enum';

@Injectable({providedIn: 'root'})
export class ChannelSettingsService {

    private _channelDisabled: BehaviorSubject<ChannelDisabledResponse> = new BehaviorSubject<ChannelDisabledResponse>(null);

    constructor(private _readingsService: ReadingsService,
                private _reportingService: ReportingService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // Setter for the channel disabled
    // -----------------------------------------------------------------------------------------------------
    set channelDisabled(value: ChannelDisabledResponse) {
        this._channelDisabled.next(value);
    }

    // -----------------------------------------------------------------------------------------------------
    // Getter for the channel disabled
    // -----------------------------------------------------------------------------------------------------
    get channelDisabled$(): Observable<ChannelDisabledResponse> {
        return this._channelDisabled.asObservable();
    }

    // -----------------------------------------------------------------
    // Gets all the form controls
    // -----------------------------------------------------------------
    getAllFormControls(isAnalog: boolean): string[] {
        if (isAnalog) {
            return ['ac_decimal_places', 'scale_min_from', 'scale_max_from', 'scale_min_to', 'scale_max_to', 'shunt_volt_input',
                'shunt_amps_scaled', 'hi_alarm_enable_ac_73', 'hi_alarm_threshold_ac_71', 'lo_alarm_enable_ac_79', 'lo_alarm_threshold_ac_77',
                'alarm_persistence_ac_70', 'instant_off_edge_offset_84'];
        } else {
            return ['hi_alarm_enabled_51', 'lo_alarm_enabled_54', 'alarm_persistence_50'];
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // Updates the form when the control is analog
    // -----------------------------------------------------------------------------------------------------
    updateAnalogForm(form: FormGroup, unit: Unit, reading: AnalogReading, unitDataPoint: UnitDataPoint, measurement: string): FormGroup {
        form.addControl(`${measurement}_enabled`, new FormControl(unitDataPoint?.enabled ?? false, Validators.required));
        form.addControl('data_point_name', new FormControl(unitDataPoint?.dataPointName, [Validators.minLength(1), Validators.maxLength(50), Validators.required]));
        form.addControl('display_unit_label', new FormControl(unitDataPoint?.displayUnitLabel, [Validators.minLength(1), Validators.maxLength(20)]));

        // Parse the conversion template
        const decimalPlaces = this.getConversionTemplateValue(unitDataPoint, 'Decimals')?.[0] ?? 4;
        form.addControl(`${measurement}_decimal_places`, new FormControl(+decimalPlaces, [Validators.min(0), Validators.max(10), Validators.required]));
        if (!this.getConversionTemplateValue(unitDataPoint, 'Decimals')?.[0]) {
            form.controls[`${measurement}_decimal_places`].markAsDirty();
        }

        // Set AC form controls
        if (measurement === 'ac') {
            const input = reading.scratchpad_2?.['ac_input_type'] ?? 1;
            form.addControl('ac_input_type', new FormControl(reading.scratchpad_2?.['ac_input_type'] ?? 1, Validators.required));

            // Add the high and low alarm boolean controls
            form.addControl('hi_alarm_enable_ac_73', new FormControl(reading?.hi_alarm_enable_ac_73 === 1 ? true : false ?? false, Validators.required));
            form.addControl('lo_alarm_enable_ac_79', new FormControl(reading?.lo_alarm_enable_ac_79 === 1 ? true : false ?? false, Validators.required));

            // Only add the deadband and threshold form controls if the hi alarm is enabled
            if (reading?.hi_alarm_enable_ac_73) {
                // threshold
                // Add the threshold form control
                form.addControl('hi_alarm_threshold_ac_71', new FormControl(reading?.hi_alarm_threshold_ac_71 ?? this.getChValue('hi_alarm_threshold_ac_71', input).default,
                    [Validators.min(this.getChValue('hi_alarm_threshold_ac_71', input).min), Validators.max(this.getChValue('hi_alarm_threshold_ac_71', input).max), Validators.required]));
                // Add the deadband form control
                form.addControl('hi_alarm_deadband_ac_72', new FormControl(reading?.hi_alarm_deadband_ac_72 ?? this.getChValue('hi_alarm_deadband_ac_72', input).default));
            }

            // Only add the deadband and threshold form controls if the low alarm is enabled
            if (reading?.lo_alarm_enable_ac_79) {
                // Add the threshold form control
                form.addControl('lo_alarm_threshold_ac_77', new FormControl(reading?.lo_alarm_threshold_ac_77 ?? this.getChValue('lo_alarm_threshold_ac_77', input).default,
                    [Validators.min(this.getChValue('lo_alarm_threshold_ac_77', input).min), Validators.max(this.getChValue('lo_alarm_threshold_ac_77', input).max), Validators.required]));
                // Add the deadband form control
                form.addControl('lo_alarm_deadband_ac_78', new FormControl(reading?.lo_alarm_deadband_ac_78 ?? this.getChValue('lo_alarm_deadband_ac_78', input).default));
            }

            form.addControl('alarm_persistence_ac_70', new FormControl(reading?.alarm_persistence_ac_70 ?? this.getChValue('alarm_persistence_ac_70', input).default, [Validators.min(this.getChValue('alarm_persistence_ac_70', input).min),
                Validators.max(this.getChValue('alarm_persistence_ac_70', input).max), Validators.required]));
        }

        // Set DC form controls
        if (measurement === 'dc') {
            const input = reading.scratchpad_2?.['dc_input_type'] ?? 2;
            form.addControl('dc_input_type', new FormControl(reading.scratchpad_2?.['dc_input_type'] ?? 1, Validators.required));

            // Add the high and low alarm boolean controls
            form.addControl('hi_alarm_enable_dc_53', new FormControl(reading?.hi_alarm_enable_dc_53 === 1 ? true : false ?? false, Validators.required));
            form.addControl('lo_alarm_enable_dc_59', new FormControl(reading?.lo_alarm_enable_dc_59 === 1 ? true : false ?? false, Validators.required));

            form.addControl('alarm_persistence_dc_50', new FormControl(reading?.alarm_persistence_dc_50 ?? this.getChValue('alarm_persistence_dc_50', input).default, [Validators.min(this.getChValue('alarm_persistence_dc_50', input).min),
                Validators.max(this.getChValue('alarm_persistence_dc_50', input).max), Validators.required]));

            form.addControl('shunt_enabled', new FormControl(reading.scratchpad_2?.shunt_enabled ?? true, Validators.required));

            let analogChannelConfiguration = RM5AnalogChannels.get(unit.brandId)[reading.channel_1-1];
            if(!analogChannelConfiguration.multipleScalling)
            {
                // get the current scale based on input type (v or mV)
                let analogChannelConfigurationScalling = analogChannelConfiguration.channelScalling[+reading.scratchpad_2[`${measurement}_input_type`]-1];

                form.addControl('scale_min_from', new FormControl(reading.scratchpad_2?.scale_min_from ?? 0, 
                    [Validators.min(analogChannelConfigurationScalling.scale_min_from), Validators.max(analogChannelConfigurationScalling.scale_max_from), Validators.required]));
                form.addControl('scale_max_from', new FormControl(reading.scratchpad_2?.scale_max_from ?? 100, 
                    [Validators.min(analogChannelConfigurationScalling.scale_min_from), Validators.max(analogChannelConfigurationScalling.scale_max_from), Validators.required]));                                
                
                form.addControl('scale_min_to', new FormControl(reading.scratchpad_2?.scale_min_to ?? 0, 
                    [Validators.min(analogChannelConfigurationScalling.scale_min_to), Validators.max(analogChannelConfigurationScalling.scale_max_to), Validators.required]));
                form.addControl('scale_max_to', new FormControl(reading.scratchpad_2?.scale_max_to ?? 100, 
                    [Validators.min(analogChannelConfigurationScalling.scale_min_to), Validators.max(analogChannelConfigurationScalling.scale_max_to), Validators.required]));
            }
            else
            {
                if (+reading.scratchpad_2[`${measurement}_input_type`] === 1) 
                {
                    form.addControl('scale_min_from', new FormControl(reading.scratchpad_2?.scale_min_from ?? 0, 
                        [Validators.min(analogChannelConfiguration.channelScalling[0].scale_min_from), Validators.max(analogChannelConfiguration.channelScalling[0].scale_max_from), Validators.required]));
                    if (unit.brandId == BrandType.RM520C || unit.brandId == BrandType.RM520S) {
                        form.addControl('scale_max_from', new FormControl(reading.scratchpad_2?.scale_max_from ?? 100, 
                            [Validators.min(analogChannelConfiguration.channelScalling[1].scale_min_from), Validators.max(analogChannelConfiguration.channelScalling[1].scale_max_from), Validators.required]));                       
                    }
                    else {
                        form.addControl('scale_max_from', new FormControl(reading.scratchpad_2?.scale_max_from ?? 100, 
                            [Validators.min(analogChannelConfiguration.channelScalling[0].scale_min_from), Validators.max(analogChannelConfiguration.channelScalling[0].scale_max_from), Validators.required]));                                                       
                    }
                
                    form.addControl('scale_min_to', new FormControl(reading.scratchpad_2?.scale_min_to ?? 0, 
                        [Validators.min(analogChannelConfiguration.channelScalling[0].scale_min_to), Validators.max(analogChannelConfiguration.channelScalling[0].scale_max_to), Validators.required]));
                    form.addControl('scale_max_to', new FormControl(reading.scratchpad_2?.scale_max_to ?? 100, 
                        [Validators.min(analogChannelConfiguration.channelScalling[0].scale_min_to), Validators.max(analogChannelConfiguration.channelScalling[0].scale_max_to), Validators.required]));
                }
                else if (+reading.scratchpad_2[`${measurement}_input_type`] === 2) 
                {
                    form.addControl('shunt_volt_input', new FormControl(reading.scratchpad_2?.shunt_volt_input ?? 50, [Validators.min(0), Validators.max(10000), Validators.required]));
                    form.addControl('shunt_amps_scaled', new FormControl(reading.scratchpad_2?.shunt_amps_scaled ?? 100, [Validators.min(0), Validators.max(1000), Validators.required]));
                }
            }

            // Get the min and max if shunt is enabled
            const min = form?.controls?.['shunt_enabled']?.value === 1 ? form?.controls?.['scale_min_to']?.value : null;
            const max = form?.controls?.['shunt_enabled']?.value === 1 ? form?.controls?.['scale_max_to']?.value : null;

            // Only add the form control if the hi alarm is enabled
            if (reading?.hi_alarm_enable_dc_53) {
                form.addControl('hi_alarm_threshold_dc_51', new FormControl(reading?.hi_alarm_threshold_dc_51 ?? this.getChValue('hi_alarm_threshold_dc_51', input).default,
                    [Validators.min(this.getChValue('hi_alarm_threshold_dc_51', input, min, max).min), Validators.max(this.getChValue('hi_alarm_threshold_dc_51', input, min, max).max), Validators.required]));
                form.addControl('hi_alarm_deadband_dc_52', new FormControl(reading?.hi_alarm_deadband_dc_52 ?? this.getChValue('hi_alarm_deadband_dc_52', input).default));
            }

            // Only add the form control if the lo alarm is enabled
            if (reading?.lo_alarm_enable_dc_59) {
                form.addControl('lo_alarm_threshold_dc_57', new FormControl(reading?.lo_alarm_threshold_dc_57 ?? this.getChValue('lo_alarm_threshold_dc_57', input).default,
                    [Validators.min(this.getChValue('lo_alarm_threshold_dc_57', input, min, max).min), Validators.max(this.getChValue('lo_alarm_threshold_dc_57', input, min, max).max), Validators.required]));
                form.addControl('lo_alarm_deadband_dc_58', new FormControl(reading?.lo_alarm_deadband_dc_58 ?? this.getChValue('lo_alarm_deadband_dc_58', input).default));
            }
        }

        // Set Instant Off form controls
        if (measurement === 'instant_off') {
            form.addControl('instant_off_edge_offset_84', new FormControl(form.get('instant_off_edge_offset_84')?.value ?? 200, [Validators.min(0), Validators.max(60000), Validators.required]));
        }
        return form;
    }

    // -----------------------------------------------------------------------------------------------------
    // Updates the form when the control is digital
    // -----------------------------------------------------------------------------------------------------
    updateDigitalForm(form: FormGroup, reading: DigitalReading, unitDataPoint: UnitDataPoint,  accumulatorDataPoint: UnitDataPoint): FormGroup {
        form.addControl('enabled', new FormControl(unitDataPoint?.enabled ?? false, Validators.required));
        form.addControl('data_point_name', new FormControl(unitDataPoint?.dataPointName, [Validators.minLength(1), Validators.maxLength(50), Validators.required]));
        form.addControl('display_unit_label', new FormControl(unitDataPoint?.displayUnitLabel, [Validators.minLength(1), Validators.maxLength(20)]));
        form.addControl('hi_alarm_enabled_51', new FormControl(reading?.hi_alarm_enabled_51 ?? false, Validators.required));
        form.addControl('lo_alarm_enabled_54', new FormControl(reading?.lo_alarm_enabled_54 ?? false, Validators.required));
        form.addControl('alarm_persistence_50', new FormControl((reading?.alarm_persistence_50 / 60) ?? 60, [Validators.min(1), Validators.max(1000000), Validators.required]));

        // digital accumulator form controls
        form.addControl('accumulator_enable_7', new FormControl(accumulatorDataPoint.enabled, Validators.required));
        if (accumulatorDataPoint.enabled === true) {

            // Add digital accumulator form controls when accumulator_enable_7 equals true
            form.addControl('count_10', new FormControl(reading?.count_10 ?? 10, [Validators.min(0), Validators.max(999999999), Validators.required]));
            form.addControl('count_alarm_enable_58', new FormControl(reading?.count_alarm_enable_58 ?? false, Validators.required));

            if (reading?.count_alarm_enable_58 === true || reading?.count_alarm_enable_58 === 1) {
                // Add digital accumulator count alarm value and reset mode when count_alarm_enable_58 = true
                form.addControl('count_alarm_value_57', new FormControl(reading?.count_alarm_value_57 ?? 10, [Validators.min(0), Validators.max(999999999), Validators.required]));
            }

            form.addControl('reset_mode_11', new FormControl(reading?.reset_mode_11 ?? 1, [Validators.min(0), Validators.max(999999999), Validators.required]));
            if (reading?.reset_mode_11 === 1) {

                // Add digital accumulator reset controls when reset mode = 1
                form.addControl('reset_at_12', new FormControl(reading?.reset_at_12 ?? 10, [Validators.min(0), Validators.max(999999999), Validators.required]));
                form.addControl('reset_to_13', new FormControl(reading?.reset_to_13 ?? 10, [Validators.min(0), Validators.max(999999999), Validators.required]));
            }
        }
        return form;
    }

    // -----------------------------------------------------------------
    // Builds the payload to update the device twin
    //  -----------------------------------------------------------------
    async buildAnalogJsonPayloadAsync(originalForm: FormGroup, updatedForm: FormGroup, reading: AnalogReading, unit: Unit, electricalCurrent: string, instanceId: number): Promise<buildPayloadResponse> {
        // Initialize the JSON request (with calculated values)
        const request = {
            'analog_acdc_31032': {}
        };

        let index = `${reading.channel_1}`;
        if (unit.brand.unitStyleId == UnitStyleEnum.Dome  || unit.brand.unitStyleId == UnitStyleEnum.IOModule)
        {
            index = `${instanceId}00${reading.channel_1}`
        }

        request['analog_acdc_31032'][index] = {};

        // Iterate through all the OMA_XML resources
        _.forEach(this._readingsService.getAnalogChannelResourceList(), (resourceData) => {
            if (updatedForm.controls[resourceData.resource]?.value !== null && updatedForm.controls[resourceData.resource]?.value !== undefined) {
                let value = this._readingsService.setValue(updatedForm.controls?.[resourceData.resource]?.value, resourceData.dataType);
                if (resourceData.resource.toLowerCase().indexOf('enable') >= 0) {
                    value = value ? 1 : 0;
                } else if (resourceData.resource.toLowerCase().indexOf('threshold') >= 0) {
                    value = this._readingsService.applyScaling(+updatedForm.controls?.[resourceData.resource]?.value, originalForm, updatedForm, electricalCurrent);
                } else if (resourceData.resource.toLowerCase().indexOf('deadband') >= 0) {
                    value = this._readingsService.applyScaling(+updatedForm.controls?.[resourceData.resource]?.value, originalForm, updatedForm, electricalCurrent);
                } else if (resourceData.resource.toLowerCase().indexOf('persistence') >= 0) {
                    value = this._readingsService.setValue(updatedForm.controls?.[resourceData.resource]?.value, resourceData.dataType) * 60;
                }
                request['analog_acdc_31032'][index][resourceData.resource] = +value;
            }
        });

        // Always set the scratchpad
        request['analog_acdc_31032'][index]['scratchpad_2'] = {};
        request['analog_acdc_31032'][index]['scratchpad_2'][0] = {};

        // Iterate through all the OMA_XML resources
        // If the current item's desired properties differs from the reported, set the desired
        const desiredProperty = unit?.unitSettings?.['properties']?.['desired']?.['analog_acdc_31032']?.[index];
        const isDesiredScratchPadEmpty = desiredProperty === undefined ? true : desiredProperty?.['scratchpad_2']?.[0] === '' || null || undefined;
        const desiredScratchPad = JSON.parse(isDesiredScratchPadEmpty ? '{}' : desiredProperty?.['scratchpad_2']?.[0]) as AnalogScratchPad;

        const reportedProperty = unit?.unitSettings?.['properties']?.['reported']?.['analog_acdc_31032']?.[index];
        const isReportedScratchPadEmpty = reportedProperty === undefined ? true : reportedProperty?.['scratchpad_2']?.[0] === '' || null || undefined;
        const reportedScratchPad = JSON.parse(isReportedScratchPadEmpty ? '{}' : reportedProperty?.['scratchpad_2']?.[0]) as AnalogScratchPad;

        // Iterate through all the scratchpad resources
        _.forEach(this._readingsService.getAnalogChannelScratchPadResourceList(), (item) => {
            // If the channel control was updated, set whatever the control is updated to
            if (updatedForm.controls[item.resource]?.value !== null && updatedForm.controls[item.resource]?.value !== undefined) {
                reading.scratchpad_2[item.resource] = this._readingsService.setValue(updatedForm?.controls[item.resource]?.value, null);
                return;
            }
            // check if a desired property exists
            if (desiredScratchPad?.[item.resource] && desiredScratchPad?.[item.resource] !== reportedScratchPad?.[item.resource]) {
                reading.scratchpad_2[item.resource] = desiredScratchPad?.[item.resource];
            }
            return;
        });
        request['analog_acdc_31032'][index]['scratchpad_2'][0] = this._readingsService.encodeScratchPad(reading.scratchpad_2);

        // Update the routine steps
        await this._reportingService.updateRoutineSteps(unit, unit.streamId, instanceId);
        return {
            payloadRequest: request,
            unit
        };
    }

    // -----------------------------------------------------------------
    // Builds the payload to update the device twin
    //  -----------------------------------------------------------------
    async buildDigitalJsonPayloadAsync(originalForm: FormGroup, updatedForm: FormGroup, reading: DigitalReading, unit: Unit, instanceId: number): Promise<buildPayloadResponse> {
        // Initialize the JSON request (with calculated values)
        let index = `${reading.channel_1 - 4}`;
        if (unit.brand.unitStyleId == UnitStyleEnum.Dome  || unit.brand.unitStyleId == UnitStyleEnum.IOModule)
        {
            index = `${instanceId}00${reading.channel_1}`
        }

        const request = {
            'digital_input_31021': {}
        };
        request['digital_input_31021'][index] = {};

        // Add name
        if (updatedForm.controls.data_point_name) {
            request['digital_input_31021'][index]['name_0'] = updatedForm.controls.data_point_name.value;
        }

        // Add Accumulator Alarm Enabled
        if (updatedForm.controls.accumulator_enable_7) {
            request['digital_input_31021'][index]['accumulator_enable_7'] = updatedForm.controls.accumulator_enable_7.value;
        }

        // Add Accumulator Count
        if (updatedForm.controls.count_10) {
            request['digital_input_31021'][index]['count_10'] = +updatedForm.controls.count_10.value;
        }

        // Add Accumulator Count Alarm Enabled
        if (updatedForm.controls.count_alarm_enable_58) {
            request['digital_input_31021'][index]['count_alarm_enable_58'] = updatedForm.controls.count_alarm_enable_58.value;
        }

        // Add Accumulator Count Alarm Value
        if (updatedForm.controls.count_alarm_value_57) {
            request['digital_input_31021'][index]['count_alarm_value_57'] = +updatedForm.controls.count_alarm_value_57.value;
        }

        // Add Accumulator Reset Mode
        if (updatedForm.controls.reset_mode_11) {
            request['digital_input_31021'][index]['reset_mode_11'] = +updatedForm.controls.reset_mode_11.value;
        }

        // Add Accumulator Reset At
        if (updatedForm.controls.reset_at_12) {
            request['digital_input_31021'][index]['reset_at_12'] = +updatedForm.controls.reset_at_12.value;
        }

        // Add Accumulator Reset To
        if (updatedForm.controls.reset_to_13) {
            request['digital_input_31021'][index]['reset_to_13'] = +updatedForm.controls.reset_to_13.value;
        }

        // Add High alarm
        if (updatedForm.controls.hi_alarm_enabled_51) {
            request['digital_input_31021'][index]['hi_alarm_enabled_51'] = updatedForm.controls.hi_alarm_enabled_51.value === true ? 1 : 0;
        }

        // Add Low alarm
        if (updatedForm.controls.lo_alarm_enabled_54) {
            request['digital_input_31021'][index]['lo_alarm_enabled_54'] = updatedForm.controls.lo_alarm_enabled_54.value === true ? 1 : 0;
        }

        // Add Alarm persistence
        if (updatedForm.controls.alarm_persistence_50) {
            request['digital_input_31021'][index]['alarm_persistence_50'] = +updatedForm.controls.alarm_persistence_50.value * 60;
        }
        // Update the routine steps
        await this._reportingService.updateRoutineSteps(unit, unit.streamId, instanceId);
        return {payloadRequest: request, unit};
    }

    // -----------------------------------------------------------------
    // Gets the default values for the channel
    //  -----------------------------------------------------------------
    getChannelConstraints(): any[] {
        return [{
            hi_alarm_threshold_ac_71: {
                default: 150000,
                min: -150000,
                max: 150000
            },
            lo_alarm_threshold_ac_77: {
                default: -150000,
                min: -150000,
                max: 150000
            },
            hi_alarm_deadband_ac_72: {
                default: 0,
                min: null,
                max: null
            },
            lo_alarm_deadband_ac_78: {
                default: 0,
                min: null,
                max: null
            },
            alarm_persistence_ac_70: {
                default: 3600,
                min: 60,
                max: 60000000
            },
            hi_alarm_threshold_dc_51: {
                default: 150000,
                min: -150000,
                max: 150000
            },
            lo_alarm_threshold_dc_57: {
                default: -150000,
                min: -150000,
                max: 150000
            },
            hi_alarm_deadband_dc_52: {
                default: 0,
                min: null,
                max: null
            },
            lo_alarm_deadband_dc_58: {
                default: 0,
                min: null,
                max: null
            },
            alarm_persistence_dc_50: {
                default: 3600,
                min: 60,
                max: 60000000
            },
            alarm_persistence_50: {
                default: 3600,
                min: 60,
                max: 60000000
            }
        }];
    }

    // -----------------------------------------------------------------
    // Gets the default, min, and max channel values
    //  -----------------------------------------------------------------
    getChValue(resource: string, inputType: number | boolean, min: number = null, max: number = null): ChannelSetting {
        const channelSetting = _.find(this.getChannelConstraints(), resource)[resource] as ChannelSetting;
        if (resource.toLowerCase().indexOf('threshold') >= 0) {
            channelSetting.default = inputType === 1 ? channelSetting.default / 1000 : channelSetting.default;
            channelSetting.min = min !== null ? min : inputType === 1 ? channelSetting.min / 1000 : channelSetting.min;
            channelSetting.max = max !== null ? max : inputType === 1 ? channelSetting.max / 1000 : channelSetting.max;
        }
        if (resource.toLowerCase().indexOf('deadband') >= 0) {
            channelSetting.default = inputType === 1 ? channelSetting.default : channelSetting.default;
            channelSetting.min = min !== null ? min : 0;
            channelSetting.max = max !== null ? max : 0;
        }
        if (resource.toLowerCase().indexOf('persistence') >= 0) {
            channelSetting.default = channelSetting.default / 60;
            channelSetting.min = channelSetting.min / 60;
            channelSetting.max = channelSetting.max / 60;
        }
        return channelSetting;
    }

    // -----------------------------------------------------------------
    // Update the conversion template
    // -----------------------------------------------------------------
    updateConversionTemplate(dataPoint: UnitDataPoint, decimalPlaces: number): string {
        // The conversion template does not exist, add a new TemplateValue
        if (!dataPoint.conversionTemplate) {
            return `<TemplateValue xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><Value>[VAL]</Value><Decimals>${decimalPlaces}</Decimals></TemplateValue>`;
        }

        // Update the Conversion Template
        let conversionTemplate = null;
        const xml2js = require('xml2js');
        xml2js.parseString(dataPoint.conversionTemplate, (err, result) => {
            // Check if the decimal node exists
            if (result?.TemplateValue?.Decimals) {
                result.TemplateValue.Decimals[0] = decimalPlaces;
            } else {
                result.TemplateValue['Decimals'] = [];
                result.TemplateValue['Decimals'][0] = decimalPlaces;
            }
            const builder = new xml2js.Builder();
            conversionTemplate = builder.buildObject(result);
        });
        return conversionTemplate.replace(/(\r\n|\n|\r)/gm, '').replace('<?xml version="1.0" encoding="UTF-8" standalone="yes"?>', '');
    }

    // -----------------------------------------------------------------
    // Gets the conversion template value
    // -----------------------------------------------------------------
    getConversionTemplateValue(dataPoint: UnitDataPoint, value: string): any {
        let result = null;
        const xml2js = require('xml2js');
        xml2js.parseString(dataPoint.conversionTemplate, (err, item) => {
            if (item?.TemplateValue?.[value]) {
                result = item?.TemplateValue?.[value];
            } else {
                result = null;
            }
        });
        return result;
    }
}
